<template>
  <div v-if="leads">
    <div class="container" v-show="filter.flag">
      <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
      <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = false" v-if="leads !== null" />
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
      <ul class="block-list" >
        <router-link 
          tag="li"
          :key="index"
          v-for="(leads, index) in leadsNew"
          :id="leads.id"
          :to = "{name: 'LeadsCreateId', params: {id: leads.id}}" 
          
        >
          <a style="display: block">
            <i :style="{background: leads.status_color_bg}"></i>
            <h4>#{{leads.id}} 
              <p>{{$root.langText.from}} {{leads.created_at | date}}</p></h4>
              <p>{{leads.client_name ? leads.client_name : '-'}}</p>
            <strong :style="{color: leads.status_color_text}">{{leads.status_name}}</strong>
            <div class="icon icon-arrow-right"></div>
          </a>
        </router-link >
      </ul>
      <router-link v-if="$root.add.can_add_lead" to="/leads/new" class="add-plus"></router-link>
      <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    <Filters @filter="filters" :filterChange="filterChange" :filter="filter"/>
  </div>
</template>





<script>
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"

export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Ліди', info: ''}
    ],
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    quantityList: '',

    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    filter: {
      flag: true,
      mod: 'leads'
    },
    btnFilter: false,
    leads: null,
    leadsNew: [],
    arrFilters: '',
    listNot: false,
    setinerval: '',
  }),



  async created() {

    //reload filters
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    }
    var efe = getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f%5Bsearch%5D=' + efe['f[search]']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)

    //title
    this.globalTitle[0].title = this.$root.langText.directory.leads;
    this.$root.title = this.globalTitle

    //base
    const res = await this.$store.dispatch('getLeads', {params: this.params, data: this.arrFilters})
    this.leads = res.data
    if(this.leads){
      this.leadsNew = res.data.list
      this.quantityList = res.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }


      localStorage.setItem('notificationLeads', false)
      var date = new Date().getTime() / 1000
      let app = this
      let arr = []
      this.setinerval = window.setInterval(async () => {
        
        app.leadsNew.forEach(function(item, index){
          arr.push(item.id)
        })
        var data = {
          action: 'quotes_list',
          ids: arr,
          date_from: date
        }
        var natification = await app.$store.dispatch('getNatification', data)
        if(natification.data.has_updates){
          const res = await app.$store.dispatch('getLeads', {params: app.params, data: app.arrFilters});
          app.leads = res.data;
          app.leadsNew = res.data.list
          app.quantityList = res.data.total_num
        }
      }, 3000)
    }
    
  },
  methods: {
    

    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)
      var arrFilters = await this.$store.dispatch('getLeads', {params: this.params, data: this.arrFilters})
      this.leadsNew = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },




    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }
  },
  components: {
    Search, Filters, Pagination, SelectedFilters
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.leads)
    }
  },
}

</script>